
import {WOW} from '../../../node_modules/wowjs/dist/wow';
import '@fancyapps/fancybox/dist/jquery.fancybox.min'
new WOW().init();

if($('.ny-banner .mob-active-nav').length > 0){
  $('.ny-banner .mob-active-nav').on('click',function(){
    if($(this).hasClass('active')){
      $(this).removeClass('active')
      $('.ny-banner .nav-list').slideUp()
    }else{
      $(this).addClass('active')
      $('.ny-banner .nav-list').slideDown()
    }
  })
}

$('body').on('click',function(e){
  if($(window).width() < 992){
    if($(e.target).parents('.ny-banner .nav').length == 0 && $('.ny-banner .mob-active-nav').length > 0){
      $('.ny-banner .mob-active-nav').removeClass('active')
      $('.ny-banner .nav-list').slideUp()
    }
  }
})

if($('.ny-banner').length > 0){
  $(document).scroll(function () {
    var scroH = $(document).scrollTop(); //滚动高度
    $('.ny-banner .bg b').css('transform','translateY('+ scroH / 5 +'px)')
  });
}