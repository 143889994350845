import './index.scss'
// 20230630
let mouseover_tid = [];
let mouseout_tid = [];
if ($(window).width() > 1024) {
  $(".header-aside .menu").each(function (index) {
    $(this).hover(
      // 鼠标进入
      function () {
        let _self = this;
        // 停止卷起事件
        clearTimeout(mouseout_tid[index]);
        // 当鼠标进入超过 0.2 秒, 展开菜单, 并记录到线程 ID 中
        mouseover_tid[index] = setTimeout(function () {
          if ($(_self).hasClass('active')) {
            $(_self).on('click', function () {
              if ($(_self).hasClass('active')) {
                $(_self).removeClass('active')
                $('.header-nav').removeClass('show-nav')
              }
            })
          } else {
            $(_self).addClass('active')
            $('.header-nav').addClass('show-nav')
            setTimeout(function () {
              if ($(window).width() > 1024) {
                $(".header-nav .nav ul li").eq(1).addClass('pc-active')
              }
            }, 800)
          }
        }, 201);
      },
      // 鼠标离开
      function () {
        let _self = this;
        // 停止展开事件
        clearTimeout(mouseover_tid[index]);
        // 当鼠标离开超过 0.2 秒, 卷起菜单, 并记录到线程 ID 中
        mouseout_tid[index] = setTimeout(function () {
        }, 201);
      });
  });
} else {
  $('.header-aside .menu').on('click', function () {
    if ($(this).hasClass('active')) {
      $(this).removeClass('active')
      $('.header-nav').removeClass('show-nav')
    } else {
      $(this).addClass('active')
      $('.header-nav').addClass('show-nav')
      setTimeout(function () {
        if ($(window).width() > 1024) {
          $(".header-nav .nav ul li").eq(1).addClass('pc-active')
        }
      }, 800)
    }
  })
}
// 20230630
$('.header-aside .back-top').on('click', function () {
  $("html, body").animate({
    scrollTop: 0
  }, {
    duration: 500,
    easing: "swing"
  });
  return false;
})

$('.header-nav .nav-search .open-search').on('click', function () {
  if ($('.header-nav .nav-search').hasClass('active')) {
    $('.header-nav .nav-search').removeClass('active')
    $('#header-search-input').blur()
  } else {
    $('.header-nav .nav-search').addClass('active')
    setTimeout(function () {
      $('#header-search-input').focus()
    }, 100)
  }
})


$('.header-nav .nav ul li .nav-title .iconfont').on('click', function () {
  let $parentsEL = $(this).parents('li')
  if ($parentsEL.hasClass('mob-active')) {
    $parentsEL.removeClass('mob-active').find('.sub-nav').slideUp()
  } else {
    $('.header-nav .nav ul li').removeClass('mob-active').find('.sub-nav').slideUp()
    $parentsEL.addClass('mob-active').find('.sub-nav').slideDown()
  }
})






//页面滚动监听
$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //滚动高度
  var viewH = $(window).height(); //可见高度
  var contentH = $(document).height(); //内容高度

  if (scroH > viewH / 2) {
    $('.header-aside .btn-icon').addClass('scroll')
  } else {
    $('.header-aside .btn-icon').removeClass('scroll')
  }
});